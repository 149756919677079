var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-agency animatedBox"},[_c('div',{staticClass:"container fluid"},[_c('filter-panel',{on:{"clear":_vm.clearFilter,"filter":_vm.filterData},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[_c('div',{staticClass:"row p-2"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-4 px-1 mb-2",attrs:{"label":"Agency Name","name":"name","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(_vm.filter['name[partial]']),callback:function ($$v) {_vm.$set(_vm.filter, 'name[partial]', $$v)},expression:"filter['name[partial]']"}}),_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-4 px-1 mb-2",attrs:{"label":"Person In Charge","name":"personInChargeName","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(_vm.filter['personInChargeName[partial]']),callback:function ($$v) {_vm.$set(_vm.filter, 'personInChargeName[partial]', $$v)},expression:"filter['personInChargeName[partial]']"}})],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.agencyTableColumns,"rows":_vm.agencyTableData,"totalRows":_vm.agencyTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('dropdown-button',{attrs:{"buttonClass":"circle"},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-v"})]},proxy:true}],null,true)},[_c('div',{staticClass:"card text-left"},[_c('fd-list-item',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.toEditAgency(props.row.id)}}},[_c('i',{staticClass:"fas fa-pen mr-2"}),_c('span',[_vm._v("Edit Agency")])]),_c('fd-list-item',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.toViewRoles(props.row.id)}}},[_c('i',{staticClass:"fas fa-user mr-2"}),_c('span',[_vm._v("Manage Roles")])])],1)])],1):(props.column.field == 'logo')?_c('span',[(props.row.logo.length < 1)?_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/image/logo/no-logo.png"),"alt":"No Logo"},on:{"error":_vm.$setAltPhoto}}):_c('img',{staticClass:"logo",attrs:{"src":_vm.$getFileURL(props.row.logo[0]),"alt":props.row.logo.name}})]):(props.column.field == 'website')?_c('span',[(props.row.website)?_c('a',{attrs:{"href":props.row.website,"target":"_blank"}},[_vm._v(_vm._s(props.row.website))]):_c('p',[_vm._v("No website")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn main mr-2",on:{"click":_vm.toAddAgency}},[_c('i',{staticClass:"fas fa-plus"})]),_c('button',{staticClass:"btn toggle",class:{ active: _vm.isFilterActive },on:{"click":_vm.filterToggle}},[_c('i',{staticClass:"fas fa-filter"})])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="manage-agency animatedBox">
    <div class="container fluid">
      <!-- =========================== Filter ============================ -->
      <filter-panel
        v-model="isFilterActive"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 sm-col-6 md-col-4 px-1 mb-2"
            label="Agency Name"
            name="name"
            type="text"
            @keyup.native.enter="filterData"
          ></fd-input>
          <fd-input
            v-model="filter['personInChargeName[partial]']"
            class="col-12 sm-col-6 md-col-4 px-1 mb-2"
            label="Person In Charge"
            name="personInChargeName"
            type="text"
            @keyup.native.enter="filterData"
          ></fd-input>
        </div>
      </filter-panel>
      <!-- ============================ Table ============================ -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="agencyTableColumns"
          :rows="agencyTableData"
          :totalRows="agencyTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="toAddAgency">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <!-- Action -->
            <span v-if="props.column.field == 'actions'" @click.stop>
              <dropdown-button buttonClass="circle">
                <template #buttonContent>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <div class="card text-left">
                  <fd-list-item
                    class="cursor-pointer"
                    @click="toEditAgency(props.row.id)"
                  >
                    <i class="fas fa-pen mr-2"></i>
                    <span>Edit Agency</span>
                  </fd-list-item>
                  <fd-list-item
                    class="cursor-pointer"
                    @click="toViewRoles(props.row.id)"
                  >
                    <i class="fas fa-user mr-2"></i>
                    <span>Manage Roles</span>
                  </fd-list-item>
                </div>
              </dropdown-button>
            </span>
            <!-- Logo -->
            <span v-else-if="props.column.field == 'logo'">
              <img
                v-if="props.row.logo.length < 1"
                src="@/assets/image/logo/no-logo.png"
                alt="No Logo"
                class="logo"
                @error="$setAltPhoto"
              />
              <img
                v-else
                :src="$getFileURL(props.row.logo[0])"
                :alt="props.row.logo.name"
                class="logo"
              />
            </span>
            <span v-else-if="props.column.field == 'website'">
              <a
                v-if="props.row.website"
                :href="props.row.website"
                target="_blank"
                >{{ props.row.website }}</a
              >
              <p v-else>No website</p>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-agency",
  mixins: [vueGoodTable],
  components: {
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    ConfirmModal: () =>
      import("@/components/GlobalComponents/ModalBox/ConfirmModal"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem")
  },
  data() {
    return {
      agencyTableData: [],
      agencyTablePagination: {},
      agencyTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "ID",
          field: "id"
        },
        {
          label: "Logo",
          field: "logo",
          tdClass: "text-center"
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Person In Charge",
          field: "personInChargeName"
        },
        {
          label: "Person In Charge Contact",
          field: "personInChargeContact"
        },
        {
          label: "Website",
          field: "website"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "name[partial]": "",
        "personInChargeName[partial]": ""
      }
    };
  },
  mounted() {
    this.getAllAgencies({ limit: this.tableParams.perPage });
  },
  methods: {
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllAgencies();
    },

    onPerPageChange(params) {
      this.updateParams({ limit: params.currentPerPage });
      this.getAllAgencies();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddAgency() {
      this.$router.push({ name: "ManageAgencyAdd" });
    },
    toEditAgency(id) {
      this.$router.push({ name: "ManageAgencyEdit", params: { id: id } });
    },
    toViewRoles(id) {
      this.$router.push({ name: "ManageAgencyRole", params: { id: id } });
    },

    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      this.getAllAgencies();
    },
    clearFilter() {
      if (this.$isFilterEmpty(this.filter)) {
        return;
      } else {
        this.tableParams.page = 1;
        this.filter = this._.mapValues(this.filter, () => "");
        this.getAllAgencies();
      }
    },

    // ========================== API Related =========================
    async getAllAgencies() {
      this.$store.commit("setIsLoading", true);
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);
        let data = await this.$store.dispatch("manageAgency/getAllAgencies", {
          ...filteredParam,
          page: this.tableParams.page,
          limit: this.tableParams.perPage
        });

        this.agencyTableData = this._.cloneDeep(data.data);
        this.agencyTablePagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-agency {
  img.logo {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
}
</style>
